/* @keyframes marquee {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  
  .marquee-container {
    overflow: hidden;
    position: relative;
  }
  
  .marquee {
    display: inline-block;
    white-space: nowrap;
  }
  
  .marquee:hover {
    animation: marquee 10s linear infinite alternate;
  }
   */

/* marquee.css */
.marquee-container {
    overflow: hidden;
    width: 100%;
  }
  
  .marquee {
    white-space: nowrap;
    display: inline-block;
    transition: transform 8s linear;
    transform: translateX(0); /* Start from the original position */
  }
  
  .marquee-container:hover .marquee {
    transform: translateX(-100%);
  }
  
  .marquee-container:not(:hover) .marquee {
    transform: translateX(0); /* Reset to the original position */
    transition: none; /* Disable the transition when not hovered */
  }
  

  .Profilemenu {
    background-color: #bb2649;
    color: white;
    flex: white;
  }


  /* Common button styles */
.button {
  padding: 10px 20px; /* Adjust padding as needed */
  border-radius: 8px; /* Rounded corners */
  font-weight: bold; /* Make text bold */
  color: white; /* Text color */
  text-align: center; /* Center the text */
  transition: background-color 0.3s ease, transform 0.3s ease; /* Transition effects */
  cursor: pointer; /* Show pointer cursor on hover */
}

/* Specific styles for Login button */
.login-button {
  background-color: #ef212b ; /* Original background color for login */
}

.login-button:hover {
  background-color: #d93647; /* Darker shade on hover */
  transform: scale(1.05); /* Scale up slightly on hover */
}

/* Specific styles for Register button */
.register-button {
  background-color: #ef212b; /* Original background color for register */
}

.register-button:hover {
  background-color: #d93647; /* Darker shade on hover */
  transform: scale(1.05); /* Scale up slightly on hover */
}
/* Targeting the cart button */
/* Base style for the cart button */
/* Cart Button Styling */
.cart-button {
  transition: transform 0.3s ease, color 0.3s ease;
  display: inline-flex;
  align-items: center;
  position: relative;
}

/* Hover effect: bounce and color change */
.cart-button:hover {
  transform: scale(1.1);
}

.cart-button:hover .cart-button-icon {
  animation: bounce 0.6s ease-in-out infinite;
  color: #d34148; /* Change color on hover */
}

/* Badge styling */
.cart-button .badge {
  animation: pulse 1.4s infinite; /* Pulse the badge */
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1.0);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1.0);
  }
}


/* Common styles for NavLink */
.nav-link {
  transition: transform 0.3s ease, color 0.3s ease, opacity 0.3s ease;
  position: relative;
}

/* Hover effect: scale and color change */
.nav-link:hover {
  transform: scale(1.05); /* Slightly increase size on hover */
  color: #d34148; /* Change color on hover */
}

/* Underline effect */
.nav-link::after {
  content: '';
  display: block;
  height: 2px;
  width: 0;
  background: #d34148; /* Underline color */
  transition: width 0.3s ease; /* Transition for underline width */
  position: absolute;
  left: 50%;
  bottom: -5px; /* Position below the text */
  transform: translateX(-50%);
}

.nav-link:hover::after {
  width: 100%; /* Full width underline on hover */
}

/* Animation after hover */
.nav-link:focus,
.nav-link:active {
  animation: pulse 0.6s ease forwards; /* Trigger pulse animation */
}

@keyframes pulse {
  0% {
    transform: scale(1.05); /* Start slightly enlarged */
  }
  50% {
    transform: scale(1); /* Return to normal size */
  }
  100% {
    transform: scale(1.02); /* Slightly shrink back */
  }
}

.text-orange-accent {
  color: #d34148; /* This is the hex code for orange */
}
.nav-shadow{
  box-shadow: 0 0px 10px rgba(0, 0, 14, 0.8);
}

.mobile-view {
  position: relative; /* To position the pseudo-element */
  padding-bottom: 5px; /* Space for the line */
  transition: color 0.3s ease; /* Transition for text color */
}

/* Pseudo-element for the underline effect */
.mobile-view::after {
  content: ''; /* Empty content for the pseudo-element */
  position: absolute; /* Absolute positioning */
  left: 50%; /* Center horizontally */
  bottom: 0; /* Position it at the bottom */
  width: 0; /* Start with no width */
  height: 2px; /* Line thickness */
  background-color: #d34148; /* Change to your desired color */
  transition: width 0.3s ease, left 0.3s ease; /* Transition for width and position */
}

/* Hover effect for mobile-view */
.mobile-view:hover {
  color: #d34148; /* Change text color on hover */
}

/* On hover, expand the line */
.mobile-view:hover::after {
  width: 100%; /* Expand to full width */
  left: 0; /* Align to the left */
}

